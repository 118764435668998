import React, { ReactNode } from "react"
import { I18nextProvider } from "react-i18next"
import { Provider as ReduxProvider } from "react-redux"
import { QueryParamProvider } from "use-query-params"
import { ReachAdapter } from "use-query-params/adapters/reach"

import i18next from "./src/i18n/config"
import store from "./src/store"

interface IWrapWithProviderProps {
    element: ReactNode
}

function WrapWithProvider(props: IWrapWithProviderProps) {
    const { element } = props

    return (
        <I18nextProvider i18n={i18next}>
            <ReduxProvider store={store}>
                <QueryParamProvider adapter={ReachAdapter}>{element}</QueryParamProvider>
            </ReduxProvider>
        </I18nextProvider>
    )
}

export default WrapWithProvider
