import i18n from "i18next"
import HttpApi from "i18next-http-backend"
import { initReactI18next } from "react-i18next"

const locale = process.env.GATSBY_LOCALE as LocaleEnum

export enum LocaleEnum {
    PL = "pl",
    EN = "en",
}

export const locales = [LocaleEnum.EN, LocaleEnum.PL]

i18n.use(HttpApi)
    .use(initReactI18next)
    .init({
        lng: locale || LocaleEnum.PL,
        fallbackLng: locale || LocaleEnum.PL,
        keySeparator: false,
        resources: {
            pl: {
                plurals: require("../locales/pl/plurals.json"),
                translations: require("../locales/pl/translations.json"),
            },
            en: {
                plurals: require("../locales/en/plurals.json"),
                translations: require("../locales/en/translations.json"),
            },
        },
        ns: ["translations", "plurals"],
        defaultNS: "translations",
        returnObjects: true,
        debug: process.env.NODE_ENV !== "production",
        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: true,
        },
        returnNull: false,
    })

i18n.languages = locales

export default i18n
