import { combineReducers, createStore } from "redux"

import breadcrumbs from "../reducers/breadcrumbs"
import filters from "../reducers/filters"

const rootReducer = combineReducers({ breadcrumbs, filters })

const store = createStore(rootReducer)

export default store
