module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-W5CK2BV","defaultDataLayer":{"platform":"gatsby"},"gtmAuth":"3sHOEjR0GtHi9CMUogMyaQ","dataLayerName":"dataLayer","enableWebVitalsTracking":true},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sages","short_name":"Sages","start_url":"/","background_color":"#ffffff","theme_color":"#31C5F4","display":"standalone","lang":"pl","icon":"/home/npmuser/static/favicon.png","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"017ee3c3cf66738e430fa68f106e0ba4"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
