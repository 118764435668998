import { ReactNode } from "react"

import { BreadcrumbsActionType } from "../actions/breadcrumbs"

const initialState = {
    customPathElements: [],
}

type actionType =
    | {
          type: BreadcrumbsActionType.SET_CUSTOM_PATH_ELEMENTS
          payload: {
              customPathElements: ReactNode
          }
      }
    | {
          type: BreadcrumbsActionType.CLEAR_PATH_ELEMENTS
      }

const breadcrumbsReducer = (state = initialState, action: actionType) => {
    switch (action.type) {
        case BreadcrumbsActionType.SET_CUSTOM_PATH_ELEMENTS:
            return {
                ...state,
                customPathElements: action.payload.customPathElements,
            }
        case BreadcrumbsActionType.CLEAR_PATH_ELEMENTS:
            return {
                ...state,
                customPathElements: [],
            }
        default:
            return state
    }
}

export default breadcrumbsReducer
