import { FiltersActionTypeEnum } from "../actions/filters"

type ActionType =
    | {
          type: FiltersActionTypeEnum.SET_CHECKBOX
          payload: {
              group: string
              value: any
              isChecked: boolean
          }
      }
    | { type: FiltersActionTypeEnum.TOGGLE_RADIO; payload: { group: string; value: any } }
    | {
          type: FiltersActionTypeEnum.SET_MINMAX
          payload: { group: string; min: number; max: number }
      }
    | { type: FiltersActionTypeEnum.CLEAR_FILTERS }

const initialState = {}

const filtersReducer = (state: Record<string, any[]> = initialState, action: ActionType) => {
    switch (action.type) {
        case FiltersActionTypeEnum.SET_CHECKBOX:
            const { group, value, isChecked } = action.payload
            !state[group] ? (state[group] = []) : null
            const currentIndex = state[group].indexOf(value)
            const newChecked = [...state[group]]

            if (currentIndex === -1 && isChecked) {
                newChecked.push(value)
            } else if (currentIndex !== -1 && !isChecked) {
                newChecked.splice(currentIndex, 1)
            }

            return {
                ...state,
                [group]: newChecked,
            }

        case FiltersActionTypeEnum.TOGGLE_RADIO: {
            const { group, value } = action.payload

            return {
                ...state,
                [group]: state[group] === value ? undefined : value,
            }
        }
        case FiltersActionTypeEnum.SET_MINMAX: {
            const { group, min, max } = action.payload
            const newMinmax = {
                min,
                max: min > max ? min : max,
            }

            return {
                ...state,
                [group]: newMinmax,
            }
        }

        case FiltersActionTypeEnum.CLEAR_FILTERS: {
            return {}
        }
        default:
            return state
    }
}
export default filtersReducer
