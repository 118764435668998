import { minmax } from "../queries/search"

export enum FiltersActionTypeEnum {
    SET_CHECKBOX = "SET_CHECKBOX",
    TOGGLE_RADIO = "TOGGLE_RADIO",
    SET_MINMAX = "SET_MINMAX",
    CLEAR_FILTERS = "CLEAR_FILTERS",
}

export const setCheckbox = (group: string, value: string | number | object | minmax, isChecked: boolean) => ({
    type: FiltersActionTypeEnum.SET_CHECKBOX,
    payload: {
        group,
        value,
        isChecked,
    },
})

export const toggleRadio = (group: string, value: string) => ({
    type: FiltersActionTypeEnum.TOGGLE_RADIO,
    payload: {
        group,
        value,
    },
})

export const setMinmax = (group: string, min: number, max: number) => ({
    type: FiltersActionTypeEnum.SET_MINMAX,
    payload: {
        group,
        min,
        max,
    },
})
export const clearFilters = () => ({
    type: FiltersActionTypeEnum.CLEAR_FILTERS,
})
