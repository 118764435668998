import { CustomBreadcrumb } from "../queries/shared-components"

export enum BreadcrumbsActionType {
    SET_CUSTOM_PATH_ELEMENTS = "SET_CUSTOM_PATH_ELEMENTS",
    CLEAR_PATH_ELEMENTS = "CLEAR_PATH_ELEMENTS",
}

export const setCustomPathElements = (customPathElements: CustomBreadcrumb[]) => ({
    type: BreadcrumbsActionType.SET_CUSTOM_PATH_ELEMENTS,
    payload: {
        customPathElements,
    },
})

export const clearPathElements = () => ({
    type: BreadcrumbsActionType.CLEAR_PATH_ELEMENTS,
})
