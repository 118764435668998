// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { setDefaults } from "@sages/cookie-consent-widget"
import { type GatsbyBrowser } from "gatsby"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import cookiesConfig from "./src/data/cookies.json"
import wrapWithProvider from "./wrap-with-provider"

export const onClientEntry = () => {
    setDefaults(cookiesConfig)
}

export const wrapRootElement = wrapWithProvider

export const shouldUpdateScroll: GatsbyBrowser["shouldUpdateScroll"] = ({ routerProps, prevRouterProps }) => {
    const shouldPreserveScrollPosition =
        prevRouterProps && prevRouterProps.location.pathname === routerProps.location.pathname

    return !shouldPreserveScrollPosition // If false is returned it makes gatsby preserve scroll position on route or url search params change
}
